@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

.holder-page {
  background-image: url(../../../public/assets/images/HolderPage/black-smoky-bg.jpeg);
  color: #fff;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &-content {
    width: 100%;
    position: absolute;
    top: 20%;
  }
  &-brand {
    width: 100%;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    &-logo {
      width: 5rem;
      margin-right: 0.7rem;
    }
    &-1 {
      font-family: 'Monaco';
      font-weight: 5em;
      font-size: 5rem;
      margin: 0;
    }
    &-2 {
      font-family: 'Sora', sans-serif;
      font-weight: 800;
      font-size: 5rem;
      margin: 0;
      color: #F7B101;
    }
  }
  &-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-txt {
      width: 50%;
      font-family: 'Monaco';
      font-weight: 800;
      font-size: 2rem;
      margin-bottom: 0;
      text-align: center;
      -webkit-text-fill-color: transparent;
      background: linear-gradient(355.72deg,#fff 33.17%,#6b6b6b 105.09%,hsla(0,0%,100%,0) 122.22%);
      -webkit-background-clip: text;
    }
    &-btn {
      padding: 1rem 2rem;
      margin-top: 2rem;
      background-color: #fff;
      color: #000;
      border-radius: 5rem;
      border: none;
      font-family: 'Sora', sans-serif;
      font-weight: 700;
      font-size: 1rem;
      cursor: pointer;
      &:hover {
        background-color: #F7B101;
        color: #fff;
      }
    }
  }
  &-footer {
    position: absolute;
    bottom: 1rem;
    width: 100%;
    &-social {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-x-icon {
      width: 1.5rem;
      margin-right: 1.5rem;
    }
    &-discord-icon {
      width: 2rem;
    }
    &-telegram-icon {
      width: 2rem;
      margin-left: 1.5rem;
    }
    &-copyright {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      font-family: 'Monaco';
      font-weight: 500;
      font-size: 0.8rem;
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .holder-page {
    background-position: center;
    &-content {
      top: 15%;
    }
    &-brand {
      &-1 {
        font-size: 4rem;
      }
      &-2 {
        font-size: 4rem;
      }
      &-logo {
        width: 4rem;
      }
    }
    &-header {
      &-txt {
        width: 45%;
        font-size: 1.5rem;
      }
    }
  }
}

@media only screen and (max-width: 912px) {
  .holder-page {
    background-position: center;
    &-content {
      top: 25%;
    }
    &-brand {
      &-1 {
        font-size: 5rem;
      }
      &-2 {
        font-size: 5rem;
      }
      &-logo {
        width: 5rem;
      }
    }
    &-header {
      &-txt {
        width: 80%;
        font-size: 2.5rem;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .holder-page {
    background-position: center;
    &-content {
      top: 20%;
    }
    &-brand {
      &-1 {
        font-size: 3rem;
      }
      &-2 {
        font-size: 3rem;
      }
      &-logo {
        width: 3rem;
      }
    }
    &-header {
      &-txt {
        width: 80%;
        font-size: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 280px) {
  .holder-page {
    background-position: center;
    &-content {
      top: 20%;
    }
    &-brand {
      &-1 {
        font-size: 2rem;
      }
      &-2 {
        font-size: 2rem;
      }
      &-logo {
        width: 2rem;
      }
    }
    &-header {
      &-txt {
        width: 80%;
        font-size: 1rem;
      }
      &-btn {
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
      }
    }
  }
}